import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { IoCamera, IoCloudUpload } from 'react-icons/io5';

const API_URL = process.env.REACT_APP_API_URL;

const FileUploadTable = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({ status: 'idle', message: '' });
  const { token } = useAuth();
  const fileInputRef = useRef(null);

  // Fetch files from API
  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${API_URL}/files`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFiles(response.data.data.files);
      setError(null);
    } catch (err) {
      setError('Failed to fetch files. Please try again later.');
      console.error('Error fetching files:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [token]);

  // Add camera capture functionality
  const handleCameraCapture = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (files?.length) {
      await handleUpload(Array.from(files));
    }
  };

  const handleUpload = async (files) => {
    setUploadStatus({ status: 'uploading', message: 'Uploading files...' });
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });

    try {
      await axios.post(`${API_URL}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        maxContentLength: Infinity, // Allow very large content length
        maxBodyLength: Infinity,   // Allow very large body length
      });
      setUploadStatus({ status: 'success', message: 'Files uploaded successfully!' });
      fetchFiles();
      setTimeout(() => {
        setUploadStatus({ status: 'idle', message: '' });
      }, 3000);
    } catch (err) {      
      setUploadStatus({ 
        status: 'error', 
        message: err.response?.data?.error || 'Failed to upload files. Please try again.' 
      });
      console.error('Error uploading files:', err);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    await handleUpload(acceptedFiles);
  }, [token]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf']
    }
  });

  const handleDelete = async (fileId) => {
    try {
      await axios.delete(`${API_URL}/files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchFiles();
    } catch (err) {
      console.log(err)
      setUploadStatus({
        status: 'error',
        message: err.response?.data?.message || 'Failed to delete file. Please try again.'
      });
      console.error('Error deleting file:', err);
      fetchFiles();
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  if (loading) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  if (error) {
    return <div className="p-6 text-center text-red-600">{error}</div>;
  }

  return (
    <div className="p-6">
      {/* Status Message */}
      {uploadStatus.message && (
        <div 
          className={`mb-4 p-4 rounded-lg ${
            uploadStatus.status === 'uploading' ? 'bg-blue-50 text-blue-700' :
            uploadStatus.status === 'success' ? 'bg-green-50 text-green-700' :
            uploadStatus.status === 'error' ? 'bg-red-50 text-red-700' : ''
          }`}
        >
          <div className="flex items-center">
            {uploadStatus.status === 'uploading' && (
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle 
                  className="opacity-25" 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                />
                <path 
                  className="opacity-75" 
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            )}
            {uploadStatus.message}
          </div>
        </div>
      )}

      {/* Upload Options */}
      <div className="flex flex-col sm:flex-row gap-4 mb-8">
        {/* Dropzone */}
        <div 
          {...getRootProps()} 
          className={`
            flex-1 border-2 border-dashed rounded-lg p-10 text-center cursor-pointer
            transition-colors duration-200 ease-in-out
            ${uploadStatus.status === 'uploading' ? 'pointer-events-none opacity-50' : ''}
            ${isDragActive 
              ? 'border-blue-500 bg-blue-50' 
              : 'border-gray-300 hover:border-gray-400'
            }
          `}
        >
          <input {...getInputProps()} disabled={uploadStatus.status === 'uploading'} />
          <div className="space-y-4">
            <div className="flex justify-center">
              <IoCloudUpload className="w-16 h-16 text-gray-400" />
            </div>
            <div className="text-gray-600">
              {uploadStatus.status === 'uploading' ? (
                <p>Uploading files...</p>
              ) : isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <div>
                  <p className="font-medium">Drop files here or click to upload</p>
                  <p className="text-sm text-gray-500 mt-1">
                    Support for images and PDF files
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Camera Button */}
        <div className="flex sm:flex-col justify-center gap-4">
          <button
            onClick={handleCameraCapture}
            className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            disabled={uploadStatus.status === 'uploading'}
          >
            <IoCamera className="w-6 h-6" />
            <span className="hidden sm:inline">Take Photo</span>
          </button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleFileInputChange}
            className="hidden"
          />
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto rounded-lg border border-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Size
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                QR Content
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                OPD DATA
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {files.map((file) => (
              <tr key={file._id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatDate(file.createdAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {file.originalName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {file.mimeType}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatFileSize(file.size)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {file.qrContent ? (
                    <div className="max-w-xs truncate" title={file.qrContent}>
                      {file.qrContent}
                    </div>
                  ) : (
                    <span className="text-gray-400 italic">No QR code found</span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span 
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${file.dataFromOPD 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-gray-100 text-gray-800'
                      }`}
                  >
                    {file.dataFromOPD ? 'Yes' : 'No'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm space-x-3">
                  <a 
                    href={file.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-900"
                  >
                    View
                  </a>
                  <button 
                    onClick={() => handleDelete(file._id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            {files.length === 0 && (
              <tr>
                <td 
                  colSpan="7" 
                  className="px-6 py-4 text-center text-sm text-gray-500"
                >
                  No files uploaded yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FileUploadTable;
