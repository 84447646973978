import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import logoLight from '../assets/images/logo-light.png';
import logoMobile from '../assets/images/logo-mobile.png';
import logoMobileLight from '../assets/images/logo-mobile-light.png';
import avatar2 from '../assets/images/avatars/avatar-2.jpg';
import { IoMenuOutline, IoCloseOutline, IoAddCircleOutline, IoNotificationsOutline } from 'react-icons/io5';
import Search from './Search';
import CreateCircle from './CreateCircle';
import NotificationCircle from './NotificationCircle';
import MessageCircle from './MessageCircle';
import ProfileCircle from './ProfileCircle';

const Header = () => {
  return (
    <>
      <header className="z-[100] h-[--m-top] fixed top-0 left-0 w-full flex items-center bg-white/80 sky-50 backdrop-blur-xl border-b border-slate-200 dark:bg-dark2 dark:border-slate-800">
        <div className="flex items-center w-full xl:px-6 px-2 max-lg:gap-10">
          <div className="2xl:w-[--w-side] lg:w-[--w-side-sm]">
            <div className="flex items-center gap-1">
              <button
                uk-toggle="target: #site__sidebar ; cls :!-translate-x-0"
                className="flex items-center justify-center w-8 h-8 text-xl rounded-full hover:bg-gray-100 xl:hidden dark:hover:bg-slate-600 group"
              >
                <IoMenuOutline className="text-2xl group-aria-expanded:hidden" />
                <IoCloseOutline className="hidden text-2xl group-aria-expanded:block" />
              </button>
              <div id="logo">
                <Link to="/timeline">
                  <img src={logo} alt="Logo" className="w-28 md:block hidden dark:!hidden" />
                  <img src={logoLight} alt="Logo Light" className="dark:md:block hidden" />
                  <img src={logoMobile} alt="Logo Mobile" className="hidden max-md:block w-20 dark:!hidden" />
                  <img src={logoMobileLight} alt="Logo Mobile Light" className="hidden dark:max-md:block w-20" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-1 relative">
            <div className="max-w-[1220px] mx-auto flex items-center">
              <Search />
              <div className="flex items-center sm:gap-4 gap-2 absolute right-5 top-1/2 -translate-y-1/2 text-black">
                <CreateCircle />    
                <NotificationCircle />
                <MessageCircle />                
                <ProfileCircle />
              </div>
            </div>
          </div>
        </div>
      </header>      
    </>
  );
};

export default Header;
