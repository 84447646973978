import React from 'react';

const SearchDropdown = () => {
  return (
    <div
      className="hidden uk-open z-10"
      uk-drop="pos: bottom-center; animation: uk-animation-slide-bottom-small; mode: click"
    >
      <div className="xl:w-[694px] sm:w-96 bg-white dark:bg-dark3 w-screen p-2 rounded-lg shadow-lg -mt-14 pt-14">
        <div className="flex justify-between px-2 py-2.5 text-sm font-medium">
          <div className="text-black dark:text-white">Recent</div>
          <button type="button" className="text-blue-500">
            Clear
          </button>
        </div>
        <nav className="text-sm font-medium text-black dark:text-white">
          {[
            {
              img: "assets/images/avatars/avatar-2.jpg",
              name: "Jesse Steeve",
              status: "Friend",
              statusColor: "text-blue-500",
            },
            {
              img: "assets/images/avatars/avatar-2.jpg",
              name: "Martin Gray",
              status: "Friend",
              statusColor: "text-blue-500",
            },
            {
              img: "assets/images/group/group-2.jpg",
              name: "Delicious Foods",
              status: "Group",
              statusColor: "text-rose-500",
            },
            {
              img: "assets/images/group/group-1.jpg",
              name: "Delicious Foods",
              status: "Page",
              statusColor: "text-yellow-500",
            },
            {
              img: "assets/images/avatars/avatar-6.jpg",
              name: "John Welim",
              status: "Friend",
              statusColor: "text-blue-500",
            },
          ].map((item, index) => (
            <a
              key={index}
              href="#"
              className="relative px-3 py-1.5 flex items-center gap-4 hover:bg-secondery rounded-lg dark:hover:bg-white/10"
            >
              <img src={item.img} alt={item.name} className="w-9 h-9 rounded-full" />
              <div>
                <div>{item.name}</div>
                <div className={`text-xs ${item.statusColor} font-medium mt-0.5`}>{item.status}</div>
              </div>
              <ion-icon
                name="close"
                className="text-base absolute right-3 top-1/2 -translate-y-1/2 md hydrated"
                role="img"
                aria-label="close"
              ></ion-icon>
            </a>
          ))}
          {[
            "Creative ideas about Business",
            "8 Facts About Writing",
          ].map((text, index) => (
            <a
              key={index}
              href="#"
              className="hidden relative px-3 py-1.5 flex items-center gap-4 hover:bg-secondery rounded-lg dark:hover:bg-white/10"
            >
              <ion-icon
                className="text-2xl md hydrated"
                name="search-outline"
                role="img"
                aria-label="search outline"
              ></ion-icon>
              {text}
            </a>
          ))}
        </nav>
        <hr className="-mx-2 mt-2 hidden" />
        <div className="flex justify-end pr-2 text-sm font-medium text-red-500 hidden">
          <a
            href="#"
            className="flex hover:bg-red-50 dark:hover:bg-slate-700 p-1.5 rounded"
          >
            <ion-icon
              name="trash"
              className="mr-2 text-lg md hydrated"
              role="img"
              aria-label="trash"
            ></ion-icon>
            Clear your history
          </a>
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;
