import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/images/logo.png';
import logoLight from '../assets/images/logo-light.png';
import favicon from '../assets/images/favicon.png';
import postImg3 from '../assets/images/post/img-3.png';
import postImg2 from '../assets/images/post/img-2.jpg';
import logoIcon from '../assets/images/logo-icon.png';

const API_URL = process.env.REACT_APP_API_URL;

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const [darkMode, setDarkMode] = useState(
    localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.theme = !darkMode ? 'dark' : 'light';
    document.documentElement.classList.toggle('dark', !darkMode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password
      });
      
      const { user, token } = response.data;
      login(user, token);
      navigate('/profile');
    } catch (err) {      
      const errorMessage = err.response?.data?.message || 'Login failed. Please check your credentials.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`sm:flex ${darkMode ? 'dark' : ''}`}>
      <div className="relative lg:w-[580px] md:w-96 w-full p-10 min-h-screen bg-white shadow-xl flex items-center pt-10 dark:bg-slate-900 z-10">
        <div className="w-full lg:max-w-sm mx-auto space-y-10">
          <Link to="/signin">
            <img
              src={darkMode ? logoLight : logo}
              className="w-28 absolute top-10 left-10"
              alt="Socialite Logo"
            />
          </Link>
          <div>
            <h2 className="text-2xl font-semibold mb-1.5">Sign in to your account</h2>
            <p className="text-sm text-gray-700 font-normal dark:text-white">
              If you haven’t signed up yet. <Link to="/signup" className="text-blue-700">Register here!</Link>
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-7 text-sm text-black font-medium dark:text-white">
            {error && <div className="text-red-500 text-sm">{error}</div>}
            {isLoading && <div className="text-primary">Loading...</div>}
            <div>
              <label htmlFor="email">Email address</label>
              <div className="mt-2.5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                  disabled={isLoading}
                  className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                />
              </div>
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <div className="mt-2.5">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="***"
                  required
                  disabled={isLoading}
                  className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2.5">
                <input id="rememberme" name="rememberme" type="checkbox" disabled={isLoading} />
                <label htmlFor="rememberme" className="font-normal">Remember me</label>
              </div>
              <a href="#" className="text-blue-700">Forgot password</a>
            </div>
            <div>
              <button 
                type="submit" 
                disabled={isLoading}
                className="button bg-primary text-white w-full disabled:opacity-50"
              >
                {isLoading ? 'Signing in...' : 'Sign in'}
              </button>
            </div>            
          </form>
        </div>
      </div>
      <div className="flex-1 relative bg-primary max-md:hidden">
        <div className="relative w-full h-full" tabIndex="-1">
          <ul className="uk-slideshow-items w-full h-full">
            <li className="w-full">
              <img
                src={postImg3}
                alt=""
                className="w-full h-full object-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
              />
              <div className="absolute bottom-0 w-full uk-transition-slide-bottom-small z-10">
                <div className="max-w-xl w-full mx-auto pb-32 px-5 z-30 relative">
                  <img className="w-12" src={logoIcon} alt="Socialite" />
                  <h4 className="text-white text-2xl font-semibold mt-7">Connect With Friends</h4>
                  <p className="text-white text-lg mt-7 leading-8">
                    This phrase is more casual and playful. It suggests that you are keeping your friends updated on what’s happening in your life.
                  </p>
                </div>
              </div>
              <div className="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SignIn;