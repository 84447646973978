import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Profile from './pages/Profile';
import SignIn from './pages/Signin';

import SignUpComponent from './pages/SignUp';
import Timeline from './pages/Timeline';

function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/signin" />;
}

function PublicRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to="/timeline" /> : children;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route 
            path="/signin" 
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            } 
          />
          <Route 
            path="/signup" 
            element={
              <PublicRoute>
                <SignUpComponent />
              </PublicRoute>
            } 
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/timeline"
            element={
              <PrivateRoute>
                <Timeline />
              </PrivateRoute>
            }
          />
          <Route 
            path="/" 
            element={
              <Navigate to={localStorage.getItem('token') ? "/timeline" : "/signin"} />
            } 
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
