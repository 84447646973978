import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState('');
  const { token, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_URL}/protected/profile`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProfileData(response.data);
      } catch (err) {
        setError('Failed to fetch profile data');
        if (err.response?.status === 401) {
          logout();
          navigate('/signin');
        }
      }
    };

    fetchProfile();
  }, [token, logout, navigate]);

  return (
    <div>
      <h2>Profile</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {profileData && (
        <div>
          <p>Email: {profileData.email}</p>
          {/* Add more profile information as needed */}
        </div>
      )}
      <button onClick={() => {
        logout();
        navigate('/signup');
      }}>Logout</button>
    </div>
  );
}

export default Profile; 