import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/images/logo.png';
import logoLight from '../assets/images/logo-light.png';
import logoIcon from '../assets/images/logo-icon.png';

import postImg5 from '../assets/images/post/img-5.png';

const API_URL = process.env.REACT_APP_API_URL;

const SignUpComponent = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [darkMode, setDarkMode] = useState(
    localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    if (!minLength) return 'Password must be at least 8 characters long';
    if (!hasUpperCase) return 'Password must include at least one uppercase letter';
    if (!hasNumber) return 'Password must include at least one number';
    if (!hasSpecialChar) return 'Password must include at least one special character (!@#$%^&*)';
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true);

    try {
      // Password validation
      const passwordError = validatePassword(formData.password);
      if (passwordError) {
        setError(passwordError);
        setIsLoading(false);
        return;
      }

      // Validate passwords match
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        setIsLoading(false);
        return;
      }

      // Validate terms acceptance
      if (!formData.acceptTerms) {
        setError('Please accept the terms of use');
        setIsLoading(false);
        return;
      }

      const response = await axios.post(`${API_URL}/auth/register`, {
        name: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password
      });

      if (response.data) {
        // Save user data to context and localStorage
        login(response.data.user);
        setSuccessMessage(response.data.message || 'Registration successful!');
        setTimeout(() => {
          navigate('/signin');
        }, 2000);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`sm:flex ${darkMode ? 'dark' : ''}`}>
      <div className="relative lg:w-[580px] md:w-96 w-full p-10 min-h-screen bg-white shadow-xl flex items-center pt-10 dark:bg-slate-900 z-10">
        <div className="w-full lg:max-w-sm mx-auto space-y-10" data-uk-scrollspy="target: > *; cls: uk-animation-scale-up; delay: 100; repeat: true">
          {/* Logo Images */}
          <Link to="/signin">
            <img
              src={darkMode ? logoLight : logo}
              className="w-28 absolute top-10 left-10"
              alt="Socialite Logo"
            />
          </Link>
  
          {/* Title */}
          <div>
            <h2 className="text-2xl font-semibold mb-1.5">Sign up to get started</h2>
            <p className="text-sm text-gray-700 font-normal">
              If you already have an account, <Link to="/signin" className="text-blue-700">Login here!</Link>
            </p>
          </div>
  
          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-7 text-sm text-black font-medium dark:text-white">
            {error && <div className="text-red-500 text-sm">{error}</div>}
            {successMessage && <div className="text-green-500 text-sm">{successMessage}</div>}
            <div className="grid grid-cols-2 gap-4 gap-y-7">
              <div>
                <label htmlFor="firstName">First name</label>
                <div className="mt-2.5">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                  />
                </div>
              </div>
  
              <div>
                <label htmlFor="lastName">Last name</label>
                <div className="mt-2.5">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                  />
                </div>
              </div>
  
              <div className="col-span-2">
                <label htmlFor="email">Email address</label>
                <div className="mt-2.5">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                  />
                </div>
              </div>
  
              <div>
                <label htmlFor="password">Password</label>
                <div className="mt-2.5">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="***"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                  />
                </div>
              </div>
  
              <div>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <div className="mt-2.5">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="***"
                    required
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="w-full rounded-lg bg-transparent shadow-sm border-slate-200 dark:border-slate-800 dark:bg-white/5"
                  />
                </div>
              </div>
  
              <div className="col-span-2">
                <label className="inline-flex items-center">
                  <input 
                    type="checkbox" 
                    id="acceptTerms"
                    name="acceptTerms"
                    checked={formData.acceptTerms}
                    onChange={handleChange}
                    className="rounded-md accent-red-800" 
                  />
                  <span className="ml-2">
                    you agree to our <a href="#" className="text-blue-700 hover:underline">terms of use</a>
                  </span>
                </label>
              </div>
  
              <div className="col-span-2">
                <button 
                  type="submit" 
                  disabled={isLoading}
                  className="button bg-primary text-white w-full disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Creating Account...' : 'Get Started'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  
      {/* Image Slider */}
      <div className="flex-1 relative bg-primary max-md:hidden">
        <div className="relative w-full h-full" tabIndex="-1" data-uk-slideshow="animation: slide; autoplay: true">
          <ul className="uk-slideshow-items w-full h-full">
            <li className="w-full">
              <img
                src={postImg5}
                alt=""
                className="w-full h-full object-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
              />
              <div className="absolute bottom-0 w-full uk-transition-slide-bottom-small z-10">
                <div className="max-w-xl w-full mx-auto pb-32 px-5 z-30 relative">
                  <img className="w-12" src={logoIcon} alt="Socialite" />
                  <h4 className="text-white text-2xl font-semibold mt-7">Connect With Friends</h4>
                  <p className="text-white text-lg mt-7 leading-8">
                    This phrase is more casual and playful. It suggests that you are keeping your friends updated on
                    what's happening in your life.
                  </p>
                </div>
              </div>
              <div className="w-full h-96 bg-gradient-to-t from-black absolute bottom-0 left-0"></div>
            </li>
          </ul>
  
          {/* Slide Navigation */}
          <div className="flex justify-center">
            <ul className="inline-flex flex-wrap justify-center absolute bottom-8 gap-1.5 uk-dotnav uk-slideshow-nav"></ul>
          </div>
        </div>
      </div>
    </div>
  );
};
  
export default SignUpComponent;