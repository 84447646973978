import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/images/logo.png';
import logoLight from '../assets/images/logo-light.png';
import avatar from '../assets/images/avatars/avatar-3.jpg';
import Header from '../components/Header';
import FileUploadTable from './table/FileUploadTable';

const Timeline = () => {
  const { user, logout } = useAuth();
  const [darkMode, setDarkMode] = useState(
    localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.theme = !darkMode ? 'dark' : 'light';
    document.documentElement.classList.toggle('dark', !darkMode);
  };

  return (
    <div>
        <Header />
        <div className="flex justify-center w-full mt-10 pt-2">
          <div className="w-[80%]">
            <FileUploadTable />
          </div>
        </div>
        
    </div>
  );
};

export default Timeline;