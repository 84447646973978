import React from 'react';
import { IoSearch } from 'react-icons/io5';
import avatar2 from '../assets/images/avatars/avatar-2.jpg';
import SearchDropdown from './SearchDropdown';

const Search = () => {
  return (
    <>
      <div 
        id="search--box" 
        className="xl:w-[680px] sm:w-96 sm:relative rounded-xl overflow-hidden z-20 bg-secondery max-md:hidden w-screen left-0 max-sm:fixed max-sm:top-2 dark:!bg-white/5"
      >
        <IoSearch className="absolute left-4 top-1/2 -translate-y-1/2" />
        <input 
          type="text" 
          placeholder="Search Friends, videos .." 
          className="w-full !pl-10 !font-normal !bg-transparent h-12 !text-sm"
        />
      </div>

      {/* Search Dropdown */}
      <div dangerouslySetInnerHTML={{ __html: "<!-- Search Dropdown -->" }} />
      
      <SearchDropdown />    
    </>
  );
};

export default Search;